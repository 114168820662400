import * as React from "react"
import { Box, FlexProps } from "theme-ui"

import { Button, ButtonProps } from "../Button"
import { Input, InputProps } from "../ui/Input"

export interface TextInlineButtonFieldProps extends FlexProps {
  inputProps?: InputProps
  buttonProps?: ButtonProps
}

export const TextInlineButtonField: React.FC<TextInlineButtonFieldProps> = ({
  inputProps,
  buttonProps,
  sx,
  ...boxProps
}) => {
  const [value, setValue] = React.useState("")

  return (
    <Box sx={{ position: "relative", ...sx }} {...boxProps}>
      <Input
        {...inputProps}
        onChangeCapture={(e) => setValue((e.target as HTMLInputElement).value)}
        inputSx={{
          height: 14,
          backgroundColor: "transparent",
          paddingInlineEnd: 28,
        }}
      />
      <Button
        {...buttonProps}
        sx={{
          width: 24,
          height: 14,
          position: "absolute",
          right: 0,
          top: 0,
          opacity: value.trim().length === 0 ? 0 : 1,
          visibility: value.trim().length === 0 ? "hidden" : "visible",
          transition: "opacity .2s, visibility .2s",
        }}
      />
    </Box>
  )
}
