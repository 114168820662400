import { useQuery } from "@tanstack/react-query"
import * as React from "react"
import Zendesk from "react-zendesk"
import medusaRequest from "../../services/request"
import { AxiosResponse } from "axios"

const ZENDESK_KEY =
  process.env.ZENDESK_KEY || "dff13a1b-c591-4ac3-a6f2-d0e5c840a58e"

const settings = {
  color: {
    theme: "#454545",
  },
}

interface ZendeskLiveChatRes {
  status: string
}

interface ZendeskWidget {
  (
    type: "webWidget:on" | "webWidget" | "webWidget:get",
    command: string,
    payload?: any
  ): void
}

declare global {
  interface Window {
    zE?: ZendeskWidget
  }
}

export const useIsZendeskLiveChatActive = () => {
  const { data, ...rest } = useQuery(
    ["zendesk-live-chat"],
    async () => {
      const { data }: AxiosResponse<ZendeskLiveChatRes> = await medusaRequest(
        "GET",
        "store/live-chat/status"
      )

      return data.status === "online" ? true : false
    },
    {
      keepPreviousData: true,
    }
  )

  return {
    isActive: data,
    ...rest,
  }
}

export const useZendesk = () => {
  const [isActivated, setIsActivated] = React.useState(false)

  return {
    isActivated,
    setIsActivated,
    ZendeskWidget: () => (
      <Zendesk
        defer
        zendeskKey={ZENDESK_KEY}
        {...settings}
        onLoaded={() => window.zE("webWidget", "open")}
      />
    ),
  }
}
