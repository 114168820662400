// External packages
import * as React from "react"
import {
  Box,
  InputProps as ThemeUiInputProps,
  Paragraph,
  ThemeUICSSObject,
  Spinner,
} from "theme-ui"
import mergeRefs from "merge-refs"

// Components
import { Icon, IconProps } from "../ui/Icon"

export interface InputProps extends ThemeUiInputProps {
  subLabel?: string
  hasFloatingLabel?: boolean
  hasError?: boolean
  inputSx?: ThemeUICSSObject
  subLabelStyles?: ThemeUICSSObject
  iconName?: IconProps["name"]
  hasReset?: boolean
  isLoading?: boolean
  onResetClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      subLabel,
      subLabelStyles,
      hasFloatingLabel,
      hasError,
      disabled,
      placeholder,
      inputSx,
      iconName,
      hasReset,
      isLoading,
      sx,
      onChange,
      onResetClick,
      ...inputProps
    },
    ref
  ) => {
    const inputRef = React.useRef(null)
    const resetButtonRef = React.useRef(null)

    return (
      <Box sx={{ ...sx }}>
        <Box sx={{ position: "relative" }}>
          {iconName && !isLoading && (
            <Icon
              name={iconName}
              sx={{
                color: "grayscale.500",
                position: "absolute",
                top: "50%",
                left: 3,
                transform: "translateY(-50%)",
                pointerEvents: "none",
              }}
            />
          )}
          {isLoading && (
            <Spinner
              sx={{
                width: 6,
                height: 6,
                position: "absolute",
                top: "50%",
                left: 3,
                transform: "translateY(-50%)",
                pointerEvents: "none",
              }}
            />
          )}
          {hasReset && (
            <Box
              as="button"
              ref={resetButtonRef}
              data-visible={false}
              onClick={(event) => {
                if (inputRef.current) {
                  inputRef.current.value = ""
                }
                event.currentTarget.dataset.visible = "false"
                onResetClick && onResetClick(event)
              }}
              sx={{
                display: "none",
                position: "absolute",
                top: "50%",
                right: 3,
                transform: "translateY(-50%)",
                color: "primary",
                padding: 0,
                backgroundColor: "transparent",
                border: 0,
                cursor: "pointer",
                "&[data-visible=true]": {
                  display: "block",
                },
              }}
            >
              <Icon name="x" size={5} />
            </Box>
          )}
          <Box
            as="input"
            ref={mergeRefs(ref, inputRef)}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (resetButtonRef.current && event.target.value.length > 0) {
                resetButtonRef.current.dataset.visible = "true"
              }
              if (resetButtonRef.current && event.target.value.length < 1) {
                resetButtonRef.current.dataset.visible = "false"
              }
              onChange && onChange(event)
            }}
            sx={{
              color: disabled ? "grayscale.600" : "primary",
              width: "100%",
              height: 14,
              fontSize: "sm",
              backgroundColor: "grayscale.white",
              border: "1px solid",
              borderColor: hasError ? "red" : "grayscale.300",
              borderRadius: 0,
              pointerEvents: disabled ? "none" : "unset",
              paddingBlockStart: hasFloatingLabel && 3,
              paddingInlineStart: iconName ? 12 : 4,
              paddingInlineEnd: hasReset ? 10 : 4,
              ":focus": {
                outline: "none",
              },
              ":not(:placeholder-shown), :focus": {
                "+ label": {
                  transform: "scale(.72) translateY(-26px)",
                },
              },
              "::placeholder": {
                color: hasFloatingLabel ? "transparent" : "grayscale.600",
                opacity: 1,
              },
              "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
              },
              "&[type=number]": { MozAppearance: "none" },
              ...inputSx,
            }}
            placeholder={placeholder}
            {...inputProps}
          />
          {hasFloatingLabel && (
            <Paragraph
              as="label"
              sx={{
                fontSize: "sm",
                color: hasError ? "red" : "grayscale.600",
                position: "absolute",
                top: "50%",
                left: 4,
                pointerEvents: "none",
                transform: "translateY(-50%)",
                transformOrigin: "left top",
                transition: "transform .2s",
              }}
            >
              {placeholder}
            </Paragraph>
          )}
        </Box>
        {subLabel && (
          <Paragraph
            sx={{
              fontSize: "sm",
              color: hasError ? "red" : "grayscale.600",
              marginBlockStart: 1,
              ...subLabelStyles,
            }}
          >
            {subLabel}
          </Paragraph>
        )}
      </Box>
    )
  }
)
